footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
    padding: 39px 4% 43px 4%;
    background-color: white;
}

.footer-section {
    width: 30%;
}

.left-footer {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
}

.left-footer ul li {
    margin-bottom: 20px;
}

#link-imgs {
    height: 44px;
}

.left-footer ul li a {
    position: relative;
    width: 43px;
    height: 43px;
    display: inline-block;
}

.left-footer ul li a svg {
    position: absolute;
}

.left-footer .email-main {
    margin-bottom: 0px;
}

.linkedin-disable {
    margin-right: 26px;
}

.center-footer {
    font-size: 20px;
    letter-spacing: 2.06px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
}

.nav-footer ul li {
    text-align: end;
    margin-bottom: 21px;
}

#footer-privacy-policy {
    margin-bottom: 0px;
}

.nav-footer ul li a {
    font-size: 20px; 
    letter-spacing: 2.06px;
    line-height: 24px;
    font-weight: bold;
    text-decoration: none;
    color: black;
}

.linkedin-disable.disabled,
.facebook-disable.disabled,
.home-href.disabled,
.contact-2-disable.disabled,
.about-2-disable.disabled,
.how-2-disable.disabled,
.privacy-href.disabled {
    cursor: not-allowed;
    pointer-events: none;
}

@media screen and (max-width: 1400px) {
    .left-footer ul .footer-email,
    .left-footer ul .footer-phone,
    .copyright .center-footer {
        font-size: 16px;
        letter-spacing: normal;
        line-height: normal;
    }
}

@media screen and (max-width: 1010px) {
    footer {
        padding: 29px 25px 26px 25px;
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        width: fit-content;
        display: flex;

    }

    .left-footer {
        width: 100%;
        order: 1;
    }

    .left-footer ul {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
    }

    #logo img,
    #logo {
        width: 236px;
        height: 71px;
    }

    .left-footer ul li a {
        height: 29.64px;
        width: 29.64px;
    }

    #link-imgs {
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-self: end;
    }

    #link-imgs a svg {
        height: 29.64px;
        width: 29.64px;
    }

    .linkedin-disable {
        margin-right: 0;
    }

    .facebook-disable {
        margin-top: 11.72px;
    }

    .left-footer ul .footer-phone {
        margin-bottom: 9px;
        width: 100%;
    }

    .left-footer ul .footer-email {
        width: 100%;
    }

    .copyright {
        order: 3;
        margin-top: 47px;
    }

    .center-footer {
        font-size: 14px;
        letter-spacing: 1.44px;
        line-height: normal;
    }

    .nav-footer {
        order: 2;
    }

    .right-footer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 40px;
    }

    .nav-footer .right-footer li {
        margin: 5px 7px 0 7px;
    }

    .nav-footer .right-footer li a {
        font-size: 14px;
        letter-spacing: 1.44px;
        line-height: normal;
    }
}

@media screen and (max-width: 340px) {
    .left-footer ul {
        justify-content: center;
    }

    .footer-phone h5,
    .footer-email h5 {
        font-size: 13px;
        text-align: center;
    }

    .linkedin-disable {
        margin-right: 26px;
    }

    .facebook-disable {
        margin-top: 0;
    }

    #link-imgs {
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-self: center;
    }
}