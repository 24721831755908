:root {
    --text-color: black;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    background-color: #ffffff;
    width: 100%;
    z-index: 1000;
    padding-top: 18px;
    padding-bottom: 14px;
}

#header-logo {
    padding-left: 2%;
}

.nav-bar {
    padding-right: 2%;
}

.nav-options {
    display: flex;
    margin-top: 6px;
}

.nav-options li {
    margin-left: 58px;
}

.nav-options li a {
    text-decoration: none;
    color: black;
    font-family: 'Atkinson Hyperlegible', sans-serif;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2.06px;
}

.header-disable.disabled {
    animation-name: notification;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.disabled-link.disabled {
    cursor: not-allowed;
    pointer-events: none;
}

@media screen and (max-width: 1050px) {
    header {
       padding-top: 22px;
    }

    #header-logo {
        padding-left: 2%;
        max-width: 240px;
        max-height: 76px;
    }

    .nav-options li a {
        font-size: 16px;
    }

    .nav-options li {
        margin-left: 40px;
    }

    .nav-bar {
        padding-right: 2%;
    }

    .nav-options #contact-top-links {
        margin-left: 0;
    }
}

@media screen and (max-width: 791px) {

    header {
        position: inherit;
        padding-top: 10px;
        height: 60px;
    }

    #header-logo {
        max-width: 200px;
        max-height: 60px;
        padding-left: 10px;
    }

    .header-disable .nav-bar {
        display: none;
    }
}