html {
    scroll-behavior: smooth;
    font-family: 'Atkinson Hyperlegible', sans-serif;
}

::selection {
    background-color: black;
    color: white;
}

.total-header {
    display: flex;
    justify-content: space-between;
}

.nav-drop-down {
    display: none;
}

.drop-trigger img {
    height: 35px;
    width: 36px;
    cursor: pointer;
    z-index: 3;
}

.drop-trigger {
    z-index: 3;
}

.drop-menu {
    position: absolute;
    background-color: white;
    list-style-type: none;
    top: 85px;
    width: 100%;
    right: 0;
    height: 173px;
    border-width: 1pt;
    border-color: black;
    border-style: solid;
    z-index: 2;
    border-bottom-left-radius: 14pt;
    border-bottom-right-radius: 14pt;
}

.drop-menu li a,
.drop-menu li {
    text-decoration: none;
    font-size: 14px;
    font-family: 'Atkinson Hyperlegible', sans-serif;
    letter-spacing: 2.74px;
    text-align: center;
    color: black;
    font-weight: bold;
    margin-top: 32px;
}

.drop-menu.inactive {
    display: none;
    z-index: 1;
    animation-name: reverse-drop;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.drop-menu.active {
    animation-name: drop-slide;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    z-index: 1;
}

.body.dialog {
    animation-name: notification;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.notif-success,
.notif-fail {
    border-radius: 14px;
    position: fixed;
    top: 40%;
    left: 0%;
    border: 2px solid white;
    z-index: 1005;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 500px;
    height: fit-content;
    font-size: 40px;
    text-align: center;
}

.close-btn {
    background-color: black;
    color: white;
    font-size: 20px;
    letter-spacing: 2.06px;
    line-height: 24px;
    font-family: 'Atkinson Hyperlegible', sans-serif;
    font-weight: bold;
    border-radius: 14px;
    border: 1px solid black;
    width: 221px;
    height: 63px;
    margin-top: 29px;
    align-self: center;
}

.close-btn:hover,
.close-btn:active {
    cursor: pointer;
    animation-name: button-hover;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.close-btn:active {
    animation-name: button-press;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
}

.notif-success.active,
.notif-fail.active {
    display: block;
}

.notif-success.inactive,
.notif-fail.inactive {
    display: none;
}

.header-notif {
    z-index: 1000;
}

.header-notif.dialog,
.drop-menu.dialog {
    animation-name: notification;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    cursor: not-allowed;
    pointer-events: none;
}

.drop-trigger {
    background-color: white;
}

.drop-menu {
    z-index: 500;
}

@keyframes drop-slide {
    from {
        top: -100px;
    }
    to {
        top: 84px;
    }
}

@keyframes reverse-drop {
    from {
        display: block;
        top: 85px;
    }
    to {
        display: none;
        top: -100px;
    }
}

@keyframes notification {
    from {
        filter: brightness(100%);
    }
    to {
        filter: brightness(25%);
    }
}

@media screen and (max-width: 791px) {
    .nav-drop-down {
        display: block;
    }

    .nav-options {
        display: none;
    }

    .total-header {
        display: flex;
        justify-content: space-between;
        background-color: white;
    }

    .header-notif {
        width: 100%;
        display: flex;
        justify-content: space-between;
        
    }

    #trigger-target {
        margin: 25px 25px 0 0;
    }
}

@media screen and (max-width: 640px) {
    .notif-success,
    .notif-fail {
        width: 75%;
        font-size: 28px;
    }

    .close-btn {
        font-size: 14px;
        height: 53px;
        width: 160px;
    }
}

@media screen and (max-width: 340px) {

    #trigger-target {
        margin: 25px 6px 0 0;
    }

}