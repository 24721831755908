.contact-form-container {
    background-color: white;
    width: 902px;
    height: fit-content;
    border-radius: 14px;
    margin-top: 49px;
}

.total-form {
    margin: 72px 88px 51px 88px;
}

.input-box-flex {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
}

.address-input {
    width: 100%;
}

.phone-input,
.email-input,
.name-input,
.check-disable {
    width: 40%;
}

input[type=text],
input[type=email] {
    border-radius: 14px;
    border: 1px solid black;
    height: 62px;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2.06px;
    line-height: 24;
    color: #7a7a83;
    opacity: 1;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 20px;
    font-family: 'Atkinson Hyperlegible', sans-serif;
}

.checkbox-div {
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    letter-spacing: 2.06px;
    font-size: 20px;
}

.checkbox-div input {
    height: 20px;
    width: 20px;
}

.checkbox-div label {
    margin-left: 14px;
    color: #404040;
}

.error-div {
    position: absolute;
}

#id-address-err {
    top: 68px;
    left: 5px;
    color: red;
}

#id-phone-err {
    top: 154px;
    left: 5px;
    color: red;
}

#id-email-err {
    top: 154px;
    left: 395px;
    color: red;
}

#id-name-err {
    top: 240px;
    left: 5px;
    color: red;
}

.input-border.red {
    border-color: red;
}

.input-border:focus {
    outline: 2px solid black;
}

.contact-form-p {
    color: #404040;
    letter-spacing: 0.48px;
    line-height: 24px;
    font-size: 20px;
}

.submit-btn {
    background-color: black;
    color: white;
    font-size: 20px;
    letter-spacing: 2.06px;
    line-height: 24px;
    font-family: 'Atkinson Hyperlegible', sans-serif;
    font-weight: bold;
    border-radius: 14px;
    border: 1px solid black;
    width: 221px;
    height: 63px;
    margin: 29px 0 29px 0;
    align-self: center;
}

.submit-btn:hover,
.submit-btn:active {
    cursor: pointer;
    animation-name: button-hover;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.submit-btn:active {
    animation-name: button-press;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
}

.para-sub-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

#email-or-call {
    margin-bottom: 17px;
}

.phone-number,
.email-main {
    margin-bottom: 14px;
    font-size: 20px;
    letter-spacing: 2.06px;
    font-weight: bold;
    line-height: 24px;
}

.address-input.disabled,
.phone-input.disabled,
.email-input.disabled,
.name-input.disabled,
.check-disable.disabled,
.submit-btn.disabled {
    cursor: not-allowed;
    pointer-events: none;
}

@keyframes button-hover {
    from {
        color: white;
        background-color: black;
    }
    to {
        color: white;
        background-color: #404040;
        border-color: #404040;
    }
}

@keyframes button-press {
    from {
        color: white;
        background-color: #404040;
        border-color: #404040;
    }
    to {
        color: black;
        background-color: #777777;
    }
}

@media screen and (max-width: 1050px) {
    .contact-form-container {
        width: 90%;
        height: fit-content;
    }

    .total-form {
        margin: 60px 44px 51px 44px;
    }

    .phone-input,
    .email-input,
    .name-input {
        width: 100%;
    }

    #id-email-err {
        top: 240px;
        left: 5px;
    }
    
    #id-name-err {
        top: 326px;
    }

    .checkbox-div {
        margin-left: 5px;
    }

    .submit-btn {
        width: 240px;
        height: 75px;
    }
}

@media screen and (max-width: 640px) {

    .contact-form-container {
        width: 85%;
    }

    .total-form {
        margin: 35px 24px 51px 24px;
    }

    input[type=text],
    input[type=email] {
        height: 45px;
        font-size: 15px;
    }

    .error-text {
        font-size: 13px;
    }

    #id-address-err {
        top: 51px;
    }
    
    #id-phone-err {
        top: 120px;
    }
    
    #id-email-err {
        top: 189px;
    }
    
    #id-name-err {
        top: 258px;
    }

    .for-checkbox {
        font-size: 15px;
    }

    #name-margin {
        margin-bottom: 5px;
    }

    .contact-form-p {
        font-size: 14px;
        letter-spacing: 0.34px;
        line-height: 17px;
    }

    .submit-btn {
        font-size: 14px;
        height: 53px;
        width: 160px;
    }

    #email-or-call {
        margin-bottom: 5px;
    }

    .phone-number,
    .email-main {
        font-size: 14px;
        letter-spacing: 1.44px;
        line-height: 17px;
        margin-bottom: 5px;
        overflow: hidden;
    }
}

@media screen and (max-width: 340px) {
    input[type=text],
    input[type=email] {
        font-size: 13px;
        letter-spacing: 0.2px;
    }
}