.privacy-policy-main h1 {
    padding-top: 200px;
    margin-bottom: 71px;
}

.privacy-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.privacy-questions {
    width: 902px;
}

.privacy-questions section h2 {
    font-size: 30px;
    letter-spacing: 3.09px;
    line-height: 37px;
    font-weight: bold;
    margin-bottom: 30px;
}

.question-answer p {
    font-size: 20px;
    letter-spacing: 0.48px;
    line-height: 24px;
    color: #404040;
    margin-bottom: 30px;
}

#h2-location-privacy {
    margin-bottom: 0px; 
    width: 451px;
}

@media screen and (max-width: 1010px) {
    .privacy-policy-main {
        padding: 110px 60px 26px 60px;
    }

    .privacy-policy-main h1 {
        padding-top: 0;
        font-size: 28px;
        letter-spacing: 6.58px;
        line-height: normal;
        margin-bottom: 17px;
    }

    .privacy-questions {
        width: fit-content;
    }

    .privacy-flex .question-answer h2 {
        font-size: 14px;
        letter-spacing: 0.196em;
        line-height: normal;
        margin-bottom: 4px;
    }

    .privacy-flex .question-answer p {
        font-size: 14px;
        letter-spacing: 0.024em;
        line-height: normal;
        margin-bottom: 10px;
    }

    #h2-location-privacy {
        font-size: 14px;
        letter-spacing: 2.74px;
        line-height: normal;
        width: fit-content;
    }
}

@media screen and (max-width: 791px) {
    .privacy-policy-main {
        padding-top: 40px;
        padding: 40px 26px 26px 26px;
    }
}