.home-top {
    background-image: url("../styles/imgs/andy-holmes-f6eWKcd8_dA-unsplash.png");
    background-position: center center;
    background-size: cover;
}

.transparent {
    background-color: rgba(255, 255, 255, 0.7);
}

h1 {
    font-size: 48px;
    text-align: center;
    font-weight: bold;
    letter-spacing: 11.28px;
    line-height: 57px;
    padding: 0 3% 0 3%;
}

#top-title {
    padding-top: 200px;
}

#top-subtitle {
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    letter-spacing: 3.09px;
    margin-top: 22px;
}

.contact-full {
    display: flex;
    justify-content: center;
}

.bullet-list-flex {
    display: flex;
    justify-content: center;
}

#bullet-list {
    margin: 80px 0 77px 0;
    background-color: white;
    border-radius: 14px;
    width: 902px;
    height: 376px;
}

#bullet-list ul {
    margin: 56px 51px 59px 82px;
}

#bullet-list ul li {
    font-size: 30px;
    letter-spacing: 3.09px;
    line-height: 37px;
    list-style-type: disc;
}

#about-us {
    background-color: white;
    margin-top: 80px;
    margin-bottom: 109px;
}

.about {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 81px;
    flex-wrap: wrap;
}

.people {
    display: flex;
    flex-direction: row;
}

.people img {
    border-radius: 14px;
    width: 375px;
    height: 497px;
    margin-bottom: 40px;
    background-color: white;
    border: 1px solid lightgray;
}

#tony img {
    margin-bottom: 0px;
}

.people .side-text-about {
    padding: 0 5% 0 5%;
    width: 258px;
}

.people .side-text-about h2 {
    font-size: 30px;
    letter-spacing: 3.09px;
    line-height: 37px;
    font-weight: bold;
    margin-bottom: 28px;
}

.people .side-text-about p {
    font-size: 20px;
    letter-spacing: 0.48px;
    line-height: 24px;
    color: #404040;
}

#how-it-works {
    background-image: url("../styles/imgs/david-kovalenko-9-qFzV9a2Zc-unsplash.png");
    background-position: center center;
    background-size: cover;
}

.how-it-flex {
    display: flex;
    justify-content: center;
}

#how-it-box {
    background-color: white;
    border-radius: 14px;
    width: 75%;
    height: fit-content;
    margin-top: 68px;
}

#how-it-box h1 {
    margin-top: 43px;
}

.work-steps {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin: 81px 40px 23px 40px;
    flex-wrap: wrap;
}

.step-boxes {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    height: fit-content;
    width: 50%;
}

.step-boxes h2 {
    font-size: 30px;
    letter-spacing: 3.09px;
    line-height: 37px;
    font-weight: bold;
    margin-top: 19px;
}

.step-boxes p {
    width: 258px;
    height: 119px;
    font-size: 20px;
    letter-spacing: 0.48px;
    line-height: 24px;
    text-align: center;
    margin-top: 14px;
}

.selling-home-flex {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

#selling-your-home {
    background-color: white;
    border-radius: 14px;
    width: 68%;
    height: fit-content;
    margin-top: 75px;
}

#selling-your-home h2 {
    margin-top: 82px;
    font-size: 30px;
    letter-spacing: 3.09px;
    line-height: 37px;
    text-align: center;
    font-weight: bold;
    margin-left: 20px;
    margin-right: 20px;
}

.flex-paragraph {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0 24px 0 24px;
}

#selling-your-home .flex-paragraph .top-para {
    padding-bottom: 0;
}

#selling-your-home p {
    margin-top: 26px;
    width: 100%;
    font-size: 20px;
    letter-spacing: 0.48px;
    line-height: 24px;
    color: #404040;
    padding-bottom: 53px;
}

#contact-2 {
    margin-top: 27px;
    padding-bottom: 61px;
}

@media screen and (max-width: 1010px) {

    .main-home {
        margin-top: 0;
    }

    #top-title {
        padding-top: 100px;
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 0.235em;
        line-height: normal;
    }

    #top-subtitle {
        margin-top: 15px;
        font-size: 14px;
        letter-spacing: 2.74px;
        line-height: 17px;
    }

    #bullet-list {
        width: fit-content;
        height: fit-content;
    }

    #bullet-list ul {
        margin: 20px 10px 20px 30px;
    }

    #bullet-list ul li {
        font-size: 20px;
        letter-spacing: 0.035em;
        line-height: normal;
    }

    #about-us {
        margin-top: 25px;
        margin-bottom: 0px;
    }

    #about-us h1 {
        font-size: 28px;
        letter-spacing: 6.58px;
        line-height: 33px;
        margin-bottom: 35px;
    }

    .about {
        flex-direction: column;
        margin-top: 25px;
        align-items: center;
    }

    .people img {
        width: 242px;
        height: 311px;
        margin-bottom: 32px;
    }

    #tony img {
        margin-bottom: 32px;
    }

    .people .side-text-about h2 {
        font-size: 20px;
        letter-spacing: 2.74px;
        line-height: 17px;
        text-align: center;
    }

    .people .side-text-about p {
        font-size: 14px;
        letter-spacing: 0.43px;
        line-height: 17px;
    }

    #how-it-box {
        width: 85%;
        margin-top: 21px;
    }

    #how-it-box h1 {
        font-size: 28px;
        letter-spacing: 6.58px;
        line-height: 33px;
        margin-top: 28px;
        margin: 28px 32px 0 32px;
    }

    .work-steps {
        margin: 35px 0 30px 0;
        flex-wrap: wrap;
        align-items: center;
    }

    .step-boxes svg {
        height: 80px;
        width: 80px;
    }

    .step-boxes h2 {
        font-size: 20px;
        letter-spacing: 0.103em;
        margin-top: -8px;
    }

    .step-boxes p {
        font-size: 16px;
        letter-spacing: 0.024em;
        line-height: normal;
        margin-top: 0px;
        height: fit-content;
        margin-bottom: 25px;
    }

    #closing-step p {
        margin-bottom: 0px;
    }

    #selling-your-home {
        width: 85%;
        margin-top: 36px;
    }

    #selling-your-home h2 {
        font-size: 20px;
        letter-spacing: 2.74px;
        line-height: normal;
        margin-top: 32px;
        padding: 0 32px 0 32px;
    }

    .flex-paragraph {
        flex-direction: column;
        align-items: center;
    }

    #selling-your-home p {
        font-size: 16px;
        letter-spacing: 0.34px;
        line-height: normal;
        padding: 0 5% 0 5%;
        width: fit-content;
    }

    #selling-your-home .top-para {
        margin-top: 23px;
    }

    #selling-your-home .bottom-para {
        margin-top: 14px;
        margin-bottom: 19px;
    }

    #contact-2 {
        margin-top: -2px;
        padding-bottom: 52px;
    }
}

@media screen and (max-width: 791px) {

    .main-home {
        margin-top: 0;
    }

    #top-title {
        padding-top: 20px;
    }

    #about-us h1 {
        margin-bottom: 25px;
    }

    .people {
        flex-direction: column;
        align-items: center;
    }

    .people .side-text-about {
        width: 75%;
    }

    .people .side-text-about h2 {
        font-size: 14px;
    }

    .people .side-text-about p {
        margin-bottom: 32px;
    }

    #second-about-p {
        margin-bottom: 23px;
    }

    .work-steps {
        flex-direction: column;
    }

    .step-boxes svg {
        height: 70px;
        width: 70px;
    }

    .step-boxes h2 {
        font-size: 14px;
    }

    .step-boxes p {
        font-size: 14px;
        margin-top: -8px;
        margin-bottom: 40px;
    }

    #selling-your-home h2 {
        font-size: 14px;
    }

    #selling-your-home p {
        font-size: 14px;
    }
}

@media screen and (max-width: 640px) {

    .step-boxes {
        width: fit-content;
    }
    
    #bullet-list {
        width: 85%;
        height: fit-content;
    }

    #bullet-list ul {
        margin: 20px 5px 20px 25px;
    }

    #bullet-list ul li {
        font-size: 14px;
        letter-spacing: 0.035em;
        line-height: normal;
    }

    .step-boxes p {
        width: fit-content;
        padding: 0 6% 0 6%;
    }
}